import React from 'react';
import { Route } from 'react-router';
import { apiDocsPlugin, ApiExplorerPage } from '@backstage/plugin-api-docs';
import {
  CatalogEntityPage,
  CatalogIndexPage,
  catalogPlugin,
} from '@backstage/plugin-catalog';
import {
  CatalogImportPage,
  catalogImportPlugin,
} from '@backstage/plugin-catalog-import';
import { ScaffolderPage, scaffolderPlugin } from '@backstage/plugin-scaffolder';
import { orgPlugin } from '@backstage/plugin-org';
import { SearchPage } from '@backstage/plugin-search';
import {
  DefaultTechDocsHome,
  TechDocsIndexPage,
  techdocsPlugin,
  TechDocsReaderPage,
} from '@backstage/plugin-techdocs';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue, ExpandableNavigation, LightBox, TextSize } from '@backstage/plugin-techdocs-module-addons-contrib';
import { VisitListener } from '@backstage/plugin-home';
import { UserSettingsPage } from '@backstage/plugin-user-settings';
import { apis } from './apis';
import { entityPage } from './components/catalog/EntityPage';
import { searchPage } from './components/search/SearchPage';
import { Root } from './components/Root';

import {
  AlertDisplay,
  OAuthRequestDialog,
  SignInPage,
} from '@backstage/core-components';
import { createApp } from '@backstage/app-defaults';
import { FlatRoutes } from '@backstage/core-app-api';
import { CatalogGraphPage } from '@backstage/plugin-catalog-graph';
import { PermissionedRoute } from '@backstage/plugin-permission-react';
import { catalogEntityCreatePermission } from '@backstage/plugin-catalog-common/alpha';
import { SignInProviderConfig } from '@backstage/core-components';
import { githubAuthApiRef, microsoftAuthApiRef } from '@backstage/core-plugin-api';
import { PlaylistIndexPage, PlaylistPage } from '@backstage-community/plugin-playlist';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import SchoolIcon from '@material-ui/icons/School';
import { useDataDogRum } from './components/Root/useDataDogRum';
import { HomepageCompositionRoot } from '@backstage/plugin-home';
import { HomePage } from './components/home/HomePage';
import { welcomePage } from './components/welcome/WelcomePage';
import { helpPage } from './components/help/HelpPage';
import { gettingStartedPage } from './components/getting-started/GettingStartedPage';
import { BazaarPage } from '@backstage-community/plugin-bazaar';

const microsoftAuthProvider: SignInProviderConfig = {
  id: 'azure-auth-provider',
  title: 'Microsoft Active Directory',
  message:
    'Sign in to Backstage Application using your Active Directory account.',
  apiRef: microsoftAuthApiRef,
};
const githubAuthProvider: SignInProviderConfig = {
  id: 'github-auth-provider',
  title: 'GitHub',
  message:
    'Sign in to Backstage Application using your GitHub account.',
  apiRef: githubAuthApiRef,
};
const app = createApp({
  apis,
  components: {
    SignInPage: props => (
      <SignInPage {...props} providers={[microsoftAuthProvider, githubAuthProvider]} />
    ),
  },
  icons: {
    accountTree: AccountTreeIcon,
    school: SchoolIcon,
  },
  bindRoutes({ bind }) {
    bind(catalogPlugin.externalRoutes, {
      createComponent: scaffolderPlugin.routes.root,
      viewTechDoc: techdocsPlugin.routes.docRoot,
      createFromTemplate: scaffolderPlugin.routes.selectedTemplate,
    });
    bind(apiDocsPlugin.externalRoutes, {
      registerApi: catalogImportPlugin.routes.importPage,
    });
    bind(scaffolderPlugin.externalRoutes, {
      registerComponent: catalogImportPlugin.routes.importPage,
      viewTechDoc: techdocsPlugin.routes.docRoot,
    });
    bind(orgPlugin.externalRoutes, {
      catalogIndex: catalogPlugin.routes.catalogIndex,
    });
  },
});

const AppProvider = app.getProvider();
const AppRouter = app.getRouter();

const routes = (
  <FlatRoutes>
    <Route path="/" element={<HomepageCompositionRoot />}>
      <HomePage />
    </Route>
    <Route path="/welcome" element={<HomepageCompositionRoot />}>
      {welcomePage}
    </Route>
    <Route path="/help" element={<HomepageCompositionRoot />}>
      {helpPage}
    </Route>
    <Route path="/getting-started" element={<HomepageCompositionRoot />}>
      {gettingStartedPage}
    </Route>
    <Route path="/catalog" element={<CatalogIndexPage />} />
    <Route
      path="/catalog/:namespace/:kind/:name"
      element={<CatalogEntityPage />}
    >
      {entityPage}
    </Route>
    <Route path="/docs" element={<TechDocsIndexPage />}>
      <DefaultTechDocsHome />
    </Route>
    <Route
      path="/docs/:namespace/:kind/:name/*"
      element={<TechDocsReaderPage />}
    >
      <TechDocsAddons>
        <ReportIssue />
        <ExpandableNavigation />
        <TextSize />
        <LightBox />
      </TechDocsAddons>
    </Route>
    <Route path="/create" element={<ScaffolderPage />} />
    <Route path="/api-docs" element={<ApiExplorerPage />} />
    <PermissionedRoute
      path="/catalog-import"
      permission={catalogEntityCreatePermission}
      element={<CatalogImportPage />}
    />
    <Route path="/search" element={<SearchPage />}>
      {searchPage}
    </Route>
    <Route path="/settings" element={<UserSettingsPage />} />
    <Route path="/catalog-graph" element={<CatalogGraphPage />} />
    <Route path="/bazaar" element={<BazaarPage />} />
    <Route path="/playlist" element={<PlaylistIndexPage />} />
    <Route path="/playlist/:playlistId" element={<PlaylistPage />} />
  </FlatRoutes>
);

const DataDogRUM = () => {
  useDataDogRum();
  return <span />; // empty component
};

const App = () => (
  <AppProvider>
    <AlertDisplay />
    <OAuthRequestDialog />
    <AppRouter>
      <DataDogRUM />
      <VisitListener />
      <Root>{routes}</Root>
    </AppRouter>
  </AppProvider>
);

export default App;
